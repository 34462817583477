import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

/* ====================================================
    Adding class to mobile main menu
   ==================================================== */ 
$(document).ready(function($){
        // Get current url
        // Select an a element that has the matching href and apply a class of 'active'. Also prepend a - to the content of the link
        var url = window.location.href;

        // Will also work for relative and absolute hrefs
       

    $('.main-nav ul.menu a').each(function () {
        if (this.href == window.location.href) {
            $(this).parents('li').find('> a').addClass("active");
        }
    });

     $('.mega-menu-container ul.menu a').each(function () {
        if (this.href == window.location.href) {
            $(this).parents('li').find('> a').addClass("active");
        }
    });

});


/* ====================================================
   ISI
   ==================================================== */ 
// var isiWaypoint = $('#isi-waypoint');

var isiwaypoint = new Waypoint({
  element: document.getElementById('isi-waypoint'),
  handler: function(direction) {
       if(direction == 'down') {
           $('#sticky-isi').hide();
       }else if(direction == 'up'){
           $('#sticky-isi').show();
           
       }
    //console.log('Direction: ' + direction);
  },
  offset: '75%'
    
})



$(window).on("load",function(){

  setTimeout(function () {
    $(window).scrollTop(0); //scroll to top on refresh
  }); 
    
   $('.backtotop').on('click', function (e) {
       $(window).scrollTop(0);
   });   
    
 
});

//----- mobile ISI -----------------------

$(window).on("resize",function(){
    
  if($(this).width()>1023){
       $('#sticky-isi').removeClass('mob_isi');
       $(".s_header").unbind();

  }

  else if($(this).width() <= 1023) {  // add mobile style and button in ISI and IND
      $('#sticky-isi').addClass('mob_isi');
         $('.s_header').on('click', function (e) {
                  //$('.mob_isi').toggleClass('open');
                  $(this).toggleClass('isiOn');
             
          if($(this).hasClass('ind') ){ // =========f mobile IND clicked 

                 //--if ind already open close it
                  if($('.sIND').hasClass('On')){ 
                      $('.mob_isi').removeClass('open');
                      $('.sIND').removeClass('On');
                  }else{
                      $('.mob_isi').addClass('open');
                      $('.sIND').addClass('On');
                      }
                
                  //---if ISI already on - close it 
                  if($('.sISI').hasClass('On')){ 
                      $('.sISI').removeClass('On');
                      $('.s_header.isi').removeClass('isiOn');
                      
                      
                  }else{ // ---if ISI not open keep it in place
                   
                  }
                    
              
            }else{ //============================= if mobile ISI clicked  
                
                //--if isi already open close it
                if($('.sISI').hasClass('On')){ 
                      $('.mob_isi').removeClass('open');
                      $('.sISI').removeClass('On');
                      $('.s_header.ind').removeClass('isiOn');
                  }else{
                      $('.mob_isi').addClass('open');
                      $('.sISI').addClass('On');
                     
                      }
                
                //---if IND already on close it 
                if($('.sIND').hasClass('On')){
                    $('.sIND').removeClass('On');
                      $('.s_header.ind').removeClass('isiOn');
                  }else{
                  
                  }
            }
        });
                                       
      console.log("small " + $(this).width());
  }

}).trigger("resize");


/* ====================================================
    Dropdown menu on click close
   ==================================================== */
$(function(){ 
     var navMain = $("#mobile-menu");

     navMain.on("click", "a.isi", null, function () {
         navMain.css('display','none');
     });
 });




/* ====================================================
    External Link Modal Pop Ups
   ==================================================== */

/* ===========================
    Third Party Links
   =========================== */

/*Patient External link*/
$('a.extlink').on("mousedown touchstart", function(e) {
    e.preventDefault(); // Prevent the href from redirecting directly
    var linkURLThirdparty = $(this).attr("href");
    warnBeforeRedirectThirdparty(linkURLThirdparty);
});

window.warnBeforeRedirectThirdparty = function warnBeforeRedirectThirdparty(linkURLThirdparty) {
    swal({
        title: "You are now leaving www.INTELENCE.com",
        html: "<p>By clicking “CONTINUE” below, you will be taken to a website that may contain links or references to other websites to which our <a href='https://www.intelence.com/privacy-policy.html'>Privacy Policy</a> may not apply. We encourage you to read the Privacy Policy of every website you visit. </p><p>You are solely responsible for your interactions with such websites.</p>",
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue', 
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: true,
        useRejections: true,
        animation: false

    }).then(okay => {
      // Redirect the user
      if (okay) {
        window.open(linkURLThirdparty);
      }
    });
};

/*HCP External link*/
$('a.extlinkHCP').on("mousedown touchstart", function(e) {
    e.preventDefault(); // Prevent the href from redirecting directly
    var linkURLThirdpartyHCP = $(this).attr("href");
    warnBeforeRedirectThirdpartyHCP(linkURLThirdpartyHCP);
});

window.warnBeforeRedirectThirdpartyHCP = function warnBeforeRedirectThirdpartyHCP(linkURLThirdpartyHCP) {
    swal({
        title: "You are now leaving Intelence.com/hcp.html",
        html: "<p>By clicking “Continue” below, you will be taken to a website that may contain links or references to other websites to which our <a href='https://www.intelence.com/hcp/privacy-policy.html'>Privacy Policy</a> may not apply. We encourage you to read the Privacy Policy of every website you visit. </p><p>You are solely responsible for your interactions with such websites.</p>",
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue', 
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: true,
        useRejections: true,
        animation: false

    }).then(okay => {
      // Redirect the user
      if (okay) {
        window.open(linkURLThirdpartyHCP);
      }
    });
};


/* ===========================
    Patient to HCP
   =========================== */
$('a.extHCP').on("mousedown touchstart", function(e) {
    e.preventDefault(); // Prevent the href from redirecting directly
    var linkURLHCP = $(this).attr("href");
    warnBeforeRedirectHCP(linkURLHCP);
});

window.warnBeforeRedirectHCP = function warnBeforeRedirectHCP(linkURLHCP) {
    swal({
        title: "Are You a Healthcare Professional?",
        html: "<p>You have selected the Healthcare Professional site entry. If you are a Healthcare Professional, click “CONTINUE” below.</p>",
        cancelButtonText: 'Cancel',
        customClass: 'HCP_popup',
        confirmButtonText: 'Continue', 
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: true,
        useRejections: true,
        animation: false

    }).then(okay => {
      // Redirect the user
      if (okay) {
        window.open(linkURLHCP, '_top');
      }
    });
};


/* ===========================
    Reveal Auto open
   =========================== */
$(document).ready(function(){
  $('#virologicModal').foundation('open')
});

$(document).ready(function($){
  //var modal = new Foundation.Reveal($('#virologicModal'));
});


